/* Button Group */
.button-group {
    display: flex;
    position: relative;
    background-color: #00017806;
    border-radius: 0.25rem;
    border: 1px solid #00017806;
    font-size: 0.875rem;
  }
  
  .button {
    width: 100%;
    padding: 10px 20px;
    padding: 0.5rem 20px;
  
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: background-color 200ms ease-out, color 200ms ease-out;
    color: #7b7c8d;
    font-size: 0.75rem;
    text-align: center;
    border-radius: 0.25rem;
  }
  
  .of-indicator {
    margin-top: 1px;
    width: 50%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    font-size: 0.75rem;
    text-align: center;
    border-radius: 5px;
    transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97),
      background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
    transform: translateX(0);
    z-index: 100;
    color: #fe6d29;
    font-weight: 600;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button:nth-child(1).active ~ .of-indicator {
    transform: translateX(0%);
  }
  
  .button:nth-child(2).active ~ .of-indicator {
    transform: translateX(100%);
  }
  
  .button:nth-child(3).active ~ .of-indicator {
    transform: translateX(200%);
  }
  