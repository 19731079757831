.signup-input {
  padding: 1rem;
  outline: none;

  background-color: #f9fafc;
  border-radius: 0.25em;
  color: #000;
  border: 2px solid transparent;
  transition: all linear 0.2s;
  font-family: 'inter';
  font-size: 1rem;
  width: 100%;
}
.signup-input:focus {
  border: 2px solid #fe6d29;
}
.signup-input::placeholder {
  color: #b2b3c4;
}
.link a {
    color: #7b7c8d;
    font-weight:600
}
.signin {
    text-decoration: none;
  color: #fe6d29;
  border-bottom: 1px solid #fe6d29;
}
