.cmnt-input{
    outline: none;
    border: none;
    padding: 5px;
    font-size: 1rem;
    
    width: 100%;

}
.cmnt-input::placeholder{
    color: #ccc;
    font-weight: 600;
}