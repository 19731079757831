.filter-input{
    border: none;
    outline: none;
    padding: .25rem .5rem;
    font-family: 'inter';
    border-radius: .25em;
    background-color: #f9fafc;
    width: 100%;
}
.filter-input:focus{
    border: 1px solid #d0551c;
}