.comment-thread-popover {
  display: none;
  background: white;
  position: absolute;
  margin-top: 4px;
  min-width: 350px;
  z-index: 1;
}

.comment-list {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}

.form-control {
  padding: 4px 8px !important;
  font-size: 12px !important;
}

.author-profile-circle {
  width: 20px;
  position: relative;
  height: 20px;
  background: black;
  margin-top: 4px;
  border-radius: 25px;
}

.author-profile-circle span {
  color: white;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  margin-top: 2px;
}

.card-body {
  padding: 12px !important;
}

.comment-list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.comment-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dfdfdf; 
  border-radius: 10px;
}
 
/* Handle */
.comment-list::-webkit-scrollbar-thumb {
  background: #afafaf; 
  border-radius: 10px;
}

/* Handle on hover */
.comment-list::-webkit-scrollbar-thumb:hover {
  background: gray; 
}

.comment-row {
  display: flex;
  font-size: 12px !important;
  margin-top: 10px;
}

.comment-author-name {
  display: block;
  font-weight: bold;
  margin-top: 4px;
}

.comment-text {
  position: relative;
  bottom: -3px;
  max-width: 350px;
}

.comment-author-photo {
  font-size: 24px;
  margin-right: 4px;
}

.comment-thread-popover-header-title {
  position: absolute;
  left: 20px;
  margin-top: 8px;
}

.comment-creation-time {
  color: #6c757d;
  font-weight: 400;
  font-size: 10px;
  margin-left: 5px;
}

.comment-input-wrapper {
  border-top: 1px solid #ced4da;
  display: flex;
  padding-top: 8px !important;
  padding: 4px;
}

.comment-input {
  border: 0;
}

.commentInput {
  margin-right: 10px !important;
  width: 100% !important;
  border-radius: 25px !important;
}

.commentInput div input {
  font-size: 14px !important;
  padding: 7.5px 14px !important;
}

.card-header:first-child {
  border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
}

.commentBtn {
  background-color: black !important;
  border-radius: 25px !important;
  padding: 0px 10px !important;
  border: none !important;
}

.commentBtn:disabled {
  padding: 0px 10px;
  background-color: #6c757d !important;
}

.comment-input:focus {
  box-shadow: none;
}

.comment-thread-popover-header {
  display: flex;
  justify-content: flex-end;
  min-height: 31px;
}

.comment-thread-popover-header button {
  border-radius: 25px;
  padding: 0px 12px;
}

.commentCardHeader {
  height: 45px;
  border-radius: 0 !important;
  padding-top: 5px;
  padding-right: 10px;
}

.commentCardBody {
  padding: 5px !important;
}

.comment-thread-selection {
  background: #dddddd;
  font-size: .75rem;
  margin-right: 10px;
  padding: 5px;
  font-style: italic;
  border-left: 2px solid;
  opacity: 0.8;
}
