.right-input{
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    color: inherit;
    outline: 0;
    border: 0;
    box-shadow: none;
    font-size: inherit;
    color:"#333"
}
.right-input::placeholder{
    color: #7b7c8d;
}
.right-user-time{
    color: #7b7c8d;
    font-size: .75rem;
    font-weight: 400;
}
.right-elements-input{
    border: none;
    outline: none;
    background-color: #f8f7f7;
    padding: 10px;
    border-radius: 5px;
    color: #6b6b6b;
}
.right-elements-input::placeholder{
color: #ccc;
}
