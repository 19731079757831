.out-input{
    outline: none;
    border: none;
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    border-radius: 5px;
}
.out-input::placeholder{
    font-family: 'courier-prime';
    color:"#0001781a"!important;
}
.out-textarea{
    font-size: 1rem;
    border: none;
    resize: none;
    outline: none;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
}
.main-act{
    width: 600px;
    border: 2px solid #a5d8ff;
    padding: 10px;
    border-radius: 5px;
}
