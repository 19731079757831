/* VerticalTabs.css */
.vertical-tabs-container {
  display: flex;
  flex-direction: row;
}

.vertical-tabs {
  flex: 1;
  border-right: 1px solid #ccc;
}

.vertical-tab-panel {
  flex: 3;
}
.mytab {
  margin-left: -10px;
}
.vertical-input {
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 11pt;
  -webkit-appearance: none;
  border: 1px solid transparent;
  vertical-align: middle;
  background-color: #f9fafc;
  border-radius: 0.25em;
  color: #000;
  border: 1px solid #9775fa;
  border: none;
  outline: none;
}
.vertical-input:focus {
  border: 1px solid #9775fa;
}
.vertical-change {
  padding: 0.375rem;
  font-size: 0.75rem;
  background-color: #00017806;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  border-color: #00017806;
}
.bill-firsthead {
  font-size: 0.875rem;
  color: #7b7c8d;
  margin-bottom: 0.25rem;
}
.bill-free {
  font-weight: bold;
  font-size: 1.25rem;
  color: #000;
}
.bill-third {
  padding-bottom: 1rem;
  line-height: 1.5;
}
.bill-cardone {
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 0.875rem;
}
.bill-cardprice {
  font-weight: 600;
  font-size: 2.25rem;
  color: #333;
}
.bill-cardpricespan {
  color: #adb5bd;
  margin-left: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.bill-cardfour {
  font-size: 0.875rem;
}
.bill-cardDis {
  padding-right: 0.25rem;
  color: #e64980;
  font-size: 0.875rem;
}
.bill-freeplus {
  color: #7b7c8d;
  font-size: 0.875rem;
  margin-bottom: 0.3rem;
}
.bill-cardflexone {
  line-height: 1;
}
.bill-cardflex {
  margin: 0.4rem 0;
  /* padding-left: 2rem; */
  /* text-indent: -1.75rem; */
  font-size: 0.875rem;
  font-weight: 600;
}
.bill-cardbtn {
  font-size: 0.875rem;
  padding: 1rem 2rem;
  color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #fe6d29;
  display: flex;
  align-items: center;
  justify-content: center;
}

.export-cardbtn {
  margin-top: 10px;
  font-size: 12px; 
  padding: 5px 7px;
  color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #fe6d29;
  display: flex;
  align-items: center;
  justify-content: center;
}



.bill-refer {
  background-color: #00017806;
  /* background-color: red; */
  padding: 0.375rem;
  border-radius: 5px;
  font-size: 0.75rem;
}
.bill-redeem {
  /* background-color: red; */
  margin-top: 5px;
  margin-left: 130px;
  color: #fe6d29;
  font-weight: 600;
  font-size: 0.875rem;
}
.bill-20of {
  font-size: 0.75rem;
}
.bill-subs {
  font-size: 0.875rem;
}
.bill-99 {
  color: #adb5bd;
  font-size: 1.25rem;
}
.bill-79 {
  color: #ff6b6b;
  margin-left: 0.25rem;
  font-size: 1.25rem;
}
.not-avatar {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #9b9bae;
  background-color: #00017806;
  cursor: pointer;
  /* width: 4rem;
  height: 4rem; */
}
.avatar-active {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #fe6d29;
  cursor: pointer;
  /* width: 4rem;
  height: 4rem; */
}
.doc-title {
  border: 1px solid transparent;
  vertical-align: middle;
  padding: 0.45rem 0.75rem;
  box-shadow: none;
  font-size: 0.875rem;

  background-color: #f9fafc;
  border-radius: 0.25em;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  color: #000;
  transition: border-color 0.2s; /* Add a smooth transition effect for the border color */
  outline: none;
  /* Initial outline color */
  border-color: #ccc;
  border: none;
}

.doc-title:focus {
  /* Change the outline color when the input is focused */
  border:1px solid #9775fa; /* You can replace this with your desired color */
}
.doc-logline {
  border: 1px solid transparent;
  /* padding: 1rem 0.75rem; */
  font-size: 0.875rem;
  background-color: #f9fafc;
  border-radius: 0.25em;
  width: 100%;
  color: #000;
  outline: none;
}
.doc-logline:focus {
  border-color: #9775fa;
}
.doc-logline:hover {
  border-color: #9775fa;
}
.pagelay-first{
  font-size: .875rem;
}

.headfoot-vertical-input{
  text-align: right;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 11pt;
  -webkit-appearance: none;
  border: 1px solid transparent;
  vertical-align: middle;
  background-color: #f9fafc;
  border-radius: 0.25em;
  color: #000;
  border: 1px solid #9775fa;
  border: none;
  outline: none;
}
.headfoot-vertical-input:focus {
  border: 1px solid #9775fa;
}


.my-vertical-tabs {
  /* display: flex; */

  background-color: red;
  display: flex;
  flex-direction: column;
  /* max-width: 200px; */
}
.docinfo-tag{
  margin-left: 5px;
  color: #333;
  font-size: 1rem;
  font-family: 'inter';
}
.doc-title::placeholder{
  font-size: 1rem;
  /* font-weight: 600; */
  opacity: .7;
  color: #b2b3c4;
  /* font-family: 'inter'; */
}
.custom-input{
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  color: inherit;
  outline: 0;
  border: 0;
  box-shadow: none;
  font-size: .875rem;
}
.custom-input::placeholder{
  color: #ccc;
}