.page-m-tag {
  color: #1971c2;
  background-color: #d0ebff;
  min-width: 1.2rem;
  padding-bottom: 2px;
  font-weight: 600;
  font-family: 'inter';
  font-size: .75rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-left: 10px;
}
