.left-drawer-time{
    color: #7b7c8d;
    font-size: .75rem;
    font-family: 'inter';
    margin-left: 5px;
}
.left-drawer-note-input{
    font-size: 11pt;
    padding: 5px;
    border: none;
    outline: none;
    background-color: #f5f5f8;
    color: #333;
}
.left-drawer-note-input::placeholder{
    color: #ccc;
}
.left-drawer-input-box{
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    background-color: #f5f5f8;
}
.left-hide{
    display: none!important;
}
.left-hide-appear:hover .left-hide{
    display: flex!important;
}
.left-drawer-emoji{
    display: none!important;
}
.left-drawer-comment-box:hover .left-drawer-emoji{
    display: flex!important;
}
.left-history-dots{
    display: none;
}
.left-history-box:hover .left-history-dots{
    display: block;
}