.scren-navbar {
    position: sticky;
    top: 0;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    z-index: 99;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    font-size: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 4rem;
    color: white;
    border-bottom: 2px solid #dbe2ea;
  }
  .scren-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .scren-home {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-size: 0.75rem;
    background-color: #00017806;
    color: #333;
    padding: 0.275rem;
    padding: 0 0.375rem;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0.25rem;
  }
  .scren-home:hover{
    color: #fe6d29;
    }
  .scren-search {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00017806;
    color: #333;
    padding: 0.275rem;
    padding: 0 0.375rem;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 0.25rem;
    font-size: 0.75rem;
  }
  .scren-search:hover{
  color: #fe6d29;
  }
  .scren-text {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .scren-upper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 0.25rem;
    font-size: 0.9rem;
  }
  .scren-main {
    font-size: 0.875rem;
    color: #333;
    /* opacity: 0.8; */
    cursor: pointer;
    font-weight: 600 !important;
    white-space: nowrap;
    font-family: 'inter-bold';
  }
  
  .scren-new {
    color: #5b5b5e;
    border: none;
    outline: none;
    width: 50px;
    font-size: 0.875rem;
  
    transition: all linear 0.5s;
  }
  .scren-new:hover {
    width: 100%;
  }
  
  .scren-slash {
    color: #b2b3c4;
    font-size: 1.2rem;
  }
  
  .scren-lower {
    cursor: pointer;
    padding-right: 10px;
    display: flex;
    gap: 10px;
    opacity: 0.7;
    font-size: 0.85rem;
  }
  .scren-outline {
    color: #b2b3c4;
    font-size: 0.67rem !important;
    font-family: 'inter' !important;
  }
  
  .scren-script {
    /* opacity: 1; */
    color: #fe6d29;
    font-size: 0.67rem !important;
    color: #b2b3c4;
    font-size: 0.67rem;
    font-weight: 600;
    font-family: 'inter' !important;
  }
  .scren-arrow {
    margin-left: -11px;
    margin-bottom: -6px;
    cursor: pointer;
    color: #000;
  }
  .scren-star {
    padding: 0 2px;
    margin: 0 -10px;
    color: #000;
    /* font-size: 1rem; */
    margin-bottom: -3px;
  }
  
  .scren-contact {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .scren-share {
    text-transform: capitalize;
    background-color: #00017806;
    border: none;
    border: 1px solid #00017806;
    border-radius: 0.25rem;
  
    padding: 0.375rem;
    font-size: 0.75rem;
    color: #fe6d29;
  }
  .scren-save {
    text-transform: capitalize;
    border: none;
    border: 1px solid #00017806;
    border-radius: 0.25rem;
    cursor: pointer;
    background-color: #fe6d29;
    padding: 0.375rem;
    font-size: 0.75rem;
    color: #fff;
  }
  
  .scren-change {
    text-transform: capitalize;
    background-color: #00017806;
    border: none;
    border: 1px solid #00017806;
    border-radius: 0.25rem;
    padding: 2px 5px;
    font-size: 0.75rem;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .scren-dot {
    padding-left: 20px;
  }
  .scren-upload {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-size: 0.75rem;
    background-color: #00017806;
    color: #333;
    padding: 0.375rem;
    border-radius: 0.25rem;
  }
  
  .scren-upload .svg {
    color: #fff;
  }
  .scren-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-size: 0.75rem;
    background-color: #00017806;
    color: #333;
    padding: 0.375rem;
    border-radius: 0.25rem;
  }
  .small-toolbar{
    display: flex;
    flex-direction: column;
  }
  
  .sm-menu{
    display: none!important;
  }
  .sm-menu2{
    display: none!important;
  }
  
  @media (max-width: 600px) {
    .scren-contact {
      display: none;
      
    }
    .scren-text {
      display: none;
    }
    .scren-icons{
      
 
        width: 300px;

        overflow-x: scroll;
padding-left: 200px;
  
    }
    .sm-menu{
      display: flex!important;
    }
    .sm-menu2{
      display: flex!important;
  
    }
  }
  
  .my_toolbar{
    display: flex;
    flex-direction: column;
  }
  .scren-board {
    color: #b2b3c4;
    font-size: 0.67rem;
    font-weight: 600;
    font-family: 'inter' !important;
  }
  .scren-ctrl {
    font-size: 0.67rem;
    background-color: #f1f3f5;
    border-radius: 0.25rem;
    font-family: 600;
  }
  .scren-highlight {
    background-color: #f1f3f5;
    padding: 5px;
    font-size: 0.75rem;
  }
  .scren-user {
    width: 30px;
    height: 30px;
    background-color: #20a6a6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
  }
  .scren-userdot {
    width: 10px;
    height: 10px;
    background-color: #59ce6e;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .headfoot-cont {
    color: #7b7c8d;
    font-weight: 400;
    font-family: 'courier';
    font-size: 0.875rem;
  }
  