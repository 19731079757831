.printablePageShadow {
  box-shadow: 0 0 5px rgba(59,65,94,0.2);
  font-size: 15px !important;
  width: 9.27in !important;
  white-space: pre-wrap;
  /* Keeps white spaces and wraps text */
  word-wrap: break-word;
  /* Break long words */
  word-break: break-word;
  /* Break long words */
  padding: 70px 120px !important;
  
  height: auto !important;
  min-height: 11.69in !important;
}

.getting-started-page {
  box-shadow: 0 0 5px rgba(59,65,94,0.2);
  font-size: 15px !important;
  width: 9.27in !important;
  white-space: pre-wrap;
  /* Keeps white spaces and wraps text */
  word-wrap: break-word;
  /* Break long words */
  word-break: break-word;
  /* Break long words */
  padding: 70px 120px !important;
}





@media (max-width: 768px) { /* For small devices */
  .getting-started-page {
    font-size: 12px !important; /* Even smaller font size */
    padding: 20px 20px !important; /* Less padding */
     width: 100% !important;
  }
}

@media (max-width: 576px) { /* For extra small devices */
  .getting-started-page {
    font-size: 11px !important; /* Smallest font size */
    padding: 10px 10px !important; /* Minimal padding */
     width: 100% !important;
  }
}


.gettingStartedToggle {
  font-size: 15px;
  position: absolute;
  left: 48%;
  bottom: -13px;
  z-index: 90;
  border-radius: 100%;
  padding: 5px 6px;
  cursor: pointer;
  background: white;
  border: 1px solid #a1a1a1;
}

.dark-mode .gettingStartedToggle {
  background: #1d1f21;
  color: white;
  border: 1px solid #a1a1a1;
}

.imageSelector {
  margin-bottom: 20px;
  max-width: 220px; /* Prevents it from exceeding 256px */
  /* overflow: hidden;
  background-color: red; */
}

.imageSelectLabel {
  position: relative;
  left: 2.7in;
  line-height: 1.5;
}
@media (max-width:768px) {
  .imageSelectorCallBox{
    position: relative;
    
    width: 100%;
  }
  .imageSelector {
    margin-bottom: 20px;


    width: 100%;
    position: relative;
    height: 200px;
    
    left: 50%;
    transform: translate(-50%);
    

  }
  

  .imageSelectLabel {
    position: absolute;
    left: 70%;
    top: 10%;
    transform: translate(-50%);
    line-height: 1.5;
    width: 100%;
  }
  .imageSelectorBox {
    /* max-width: 100%; */
    font-size: 14px; 
    padding: 8px;
    width: 100%;
  }

}

.dark-mode .imageSelectLabel {
  color: rgb(201, 201, 201);
}

.imageSelectorBox {
  width: 120px;
  height: 120px;
  padding-top: 25px;
  border: 1px solid black;
  font-family: 'courier_prime',courier_prime,courier_primecyrillic,CourierPrime,Courier,courier,CourierNext,monospace,monospace;
  text-align: center;

}




.dark-mode .imageSelectorBox {
  border: 1px solid white;
}

.imageSelectorDiv {
  position: relative;
  width: 140px;
  left: 3.1in;
}

@media (max-width:768px) {
  .imageSelectorDiv {
    /* position: relative; */
    position: relative;
    /* height: 200px; */
    left: 50%;
    transform: translate(-50%)
}
}

.imageSelectorImg {
  width: 120px;
  height: 120px;
}

.imageSelectorIcon {
  position: absolute;
  top: 10px;
  right: 1px;
  cursor: pointer;
}

.dark-mode .imageSelectorIcon {
  color: rgb(201, 201, 201);
}

.script-zoom-100 {
  zoom: 1.0;
}

.script-zoom-90 {
  zoom: 0.9;
}

.script-zoom-80 {
  zoom: 0.8;
}

.script-zoom-70 {
  zoom: 0.7;
}

.script-zoom-60 {
  zoom: 0.6;
}

.script-zoom-110 {
  zoom: 1.1;
}

.script-zoom-120 {
  zoom: 1.2;
}

.script-zoom-130 {
  zoom: 1.3;
}

.script-zoom-140 {
  zoom: 1.4;
}

.script-zoom-150 {
  zoom: 1.5;
}

.script-zoom-160 {
  zoom: 1.6;
}

@media print {
  .printablePageShadow {
    height: 11.69in !important;
    padding-top: 4in !important;
    box-shadow: none;

  }

  .print-hide {
    display: none;
  }
}