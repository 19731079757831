/* Chatbot Toggler */
.chatbot-toggler {
  position: fixed;
  bottom: 30px;
  right: 35px;
  outline: none;
  border: none;
  height: 50px;
  width: 50px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fe6d29;
  transition: all 0.2s ease;
}

.chatbot-toggler span {
  color: #fff;
  position: absolute;
}

.chatbot-toggler span:last-child {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.chatbot-toggler span:first-child {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.chatbot-toggler:hover {
  transform: rotate(15deg);
}

/* Chatbot Drawer */
.chatbot {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 420px;
  background: #fff;
  border-radius: 15px 0 0 15px;
  box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1), 0 32px 64px -48px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.chatbot header {
  padding: 16px 0;
  position: relative;
  text-align: center;
  color: #fff;
  background: #fe6d29;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chatbot header span {
  position: absolute;
  right: 15px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}

header h2 {
  font-size: 1.4rem;
}

/* Chatbox */
.chatbox {
  overflow-y: auto;
  flex: 1;
  padding: 30px 20px 100px;
  max-height: 400px;
}

.chatbox .chat {
  display: flex;
  list-style: none;
  position: relative;
}

.message-response {
  padding-top: 20px;
  position: relative;
}

.move-to-page {
  position: absolute;
  font-weight: 400;
  top: 8px;
  color: #fff;
  white-space: nowrap;
  font-size: 10px;
  background-color: #fe6d29;
  font-family: inter;
  padding: 5px 6px;
  border-radius: .25rem;
  cursor: pointer;
  border: none;
  right: 10px;
}


.chatbox .outgoing {
  margin: 20px 0;
  justify-content: flex-end;
}

.chatbox .incoming span {
  width: 32px;
  height: 32px;
  color: #fff;
  text-align: center;
  line-height: 32px;
  align-self: flex-end;
  background: #fe6d29;
  border-radius: 4px;
  margin: 0 10px 7px 0;
}

.chatbox .chat p {
  white-space: pre-wrap;
  padding: 12px 16px;
  border-radius: 10px 10px 0 10px;
  max-width: 75%;
  color: #fff;
  font-size: 0.95rem;
  background: #fe6d29;
}

.chatbox .chat .message-response {
  padding-top: 30px;
}

.chatbox .incoming p {
  border-radius: 10px 10px 10px 0;
  color: #000;
  background: #f2f2f2;
}

/* Input Area */
.chat-input {
  display: flex;
  gap: 5px;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  background: #fff;
}

.chat-input textarea {
  height: 200px;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  padding: 15px;
  font-size: 0.95rem;
}

.chat-input span {
  align-self: flex-end;
  color: #fe6d29;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.35rem;
}

@media (max-width: 490px) {
  .chatbot {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
