.not-avatar {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #9b9bae !important;
    background-color: #00017806 !important;
    cursor: pointer;
    /* width: 4rem;
    height: 4rem; */
  }
  .avatar-active {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #fff !important;
    background-color: #fe6d29 !important;
    cursor: pointer;
    /* width: 4rem;
    height: 4rem; */
  }