.comment {
  background-color: #feeab5 !important;
}

.comment.is-active {
  background-color: #fcc935 !important;
}

.comment-icon {
  /* position: absolute !important; */
  background-color: transparent !important ;
  /* right: -68px !important;
  bottom: 5px !important; */
  float: inline-end;
  margin: auto;
  position: relative;
  left: 30px;
  cursor: pointer;
}
