.tag-input-style {
    border: none;
    outline: none;
    background-color: #f9fafc;
    padding: 0.45rem 0.75rem;
    border-radius: 0.25em;
    font-family: 'inter';
    width: 100%;
  }
  
  .tag-input-style:focus {
    border: 1px solid #d0551c;
  }