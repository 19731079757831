.DarkModeSwitch {
  position: fixed;
  top: 0px;
  right: 15px;
}

.html-darkMode {
  background-color: #0f0f0f;
}

.dark-mode .base-screen {
  background-color: #0f0f0f;
}

.dark-mode .page {
  background-color: #1d1f21 !important;
}

.dark-mode .getting-started-page {
  background-color: #1d1f21 !important;
}

.dark-mode .header-input {
  background: #1d1f21;
}

.dark-mode span {
  color: white;
}

.dark-mode .comment span {
  color: black !important;
}

.dark-mode .comment-thread-popover {
  background: #1d1f21;
  color: white;
}

.dark-mode .comment-thread-selection {
  background: #3c3c3c;
}
.dark-mode .footer {
  background: #1d1f21;
}

.dark-mode .scren-navbar {
  background-color: #000;
}

.dark-mode .scren-home {
  color: white;
  background-color: #333;
}
.dark-mode .scren-search {
  color: white;
  background-color: #333;
}
.dark-mode .scren-slash {
  color: white;
}
.dark-mode .scren-new {
  color: white;
  background-color: #000;
}
.dark-mode .scren-main {
  color: white;
}
.dark-mode .scren-share {
  color: white;
  background-color: #333;
}
.dark-mode .scren-change {
  color: white;
  background-color: #333;
}
.dark-mode .scren-upload {
  background-color: #333;
  color: white;
}
.dark-mode .scren-settings {
  background-color: #333;
  color: white;
}
.dark-mode .scren-navbar {
  background-color: #000;
}

.dark-mode .scren-home {
  color: white;
  background-color: #333;
}
.dark-mode .scren-search {
  color: white;
  background-color: #333;
}
.dark-mode .scren-star {
  padding: 0 2px;
  margin: 0 -10px;
  color: #fff;
  font-size: 1rem;
  margin-bottom: -5px;
}
.dark-mode .mod-card-input {
  background-color: #161c24;
  color: #d9dbde;
}
.dark-mode .beautiful-textarea {
  background-color: #161c24;
  /* color: #D9DBDE; */
}
.dark-mode .dark-bg {
  background-color: #161c24;
}
.dark-mode .light-bg {
  background-color: #1d1f2160;
}
.dark-mode .dark-text {
  color: #d9dbde;
}
.dark-mode .dark-light-text {
  color: #7b7c8d;
}
.dark-mode .dark-bg:hover {
  background-color: #1d1f2160;
}

.dark-mode .light-bg-hover:hover {
  background-color: #161c24;
}
.dark-mode .light-bg-hover {
  background-color: #1d1f2160;
}
.dark-mode .dark-dash-bg {
  background-color: #0f0f0f;
}
.dark-mode .dark-bgg {
  background-color: #161c24;
}
.dark-mode .dark-bg-text {
  color: #000;
}
.dark-mode .suggestedMention {
  color: #000;
}
