.stash-input-style {
    border: none;
    outline: none;
    background-color: #f9fafc;
    padding: 0.45rem 0.75rem;
    border-radius: 0.25em;
    font-family: 'inter';
    padding-top: 2rem;
    width: 100%;
  }
  
  .stash-input-style:focus {
    border: 1px solid #d0551c;
}


.stash-beautiful-textarea {
    width: 100%;
    /* padding: 10px; */
    padding-top: 1.5rem;
    border: none;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1rem;
    /* background-color: #fff; */
    /* border-radius: 5px; */
    /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
    resize: none; /* Prevents textarea resizing */
    outline: none; /* Removes the default textarea outline */
  }

  .stash-beautiful-textarea:focus {
    /* border: 1px solid #d0551c; */
}
