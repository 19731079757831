.board-card-input {
  font-size: 0.875rem;
  padding: 0.5rem;
  font-family: 'inter';
  border: none;
  outline: none;
  border-radius: 5px;
  width: 100%;
}
.board-card-input::placeholder {
  color: #b2b3c4;
}
.board-card-box {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

.board-beautiful-textarea {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
  resize: none; /* Prevents textarea resizing */
  outline: none; /* Removes the default textarea outline */
  font-family: 'inter';
}

/* .hide{
    display: none!important;
}
.visible:hover .hide{
    display: flex!important;
} */