.dash-scr-input {
    border: none;
    outline: none;
    padding: 0.45rem 0.75rem;
    background-color: #f9fafc;
    border-radius: 0.25em;
    width: 100%;
    font-family: 'inter';
  }
  .dash-scr-input:focus {
    border: 1px solid #d0551c;
  }
  
  