.scren-boardcard {
  background-color: #fff;
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.scren-boardheading {
  font-size: 1.25rem;
}
.scren-choosetemp {
  font-size: 0.875rem;
  margin-bottom: 2rem;
}
.board-buttons {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}
.board-button {
  font-size: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.375rem;
  border: 1px solid #00017806;
  background-color: #00017806;
}
