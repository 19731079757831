/* styles.css */
.input-style {
    border: none;
    outline: none;
    background-color: #f9fafc;
    padding: 0.45rem 0.75rem;
    border-radius: 0.25em;
    font-family: 'inter';
    width: 100%;
  }
  
  .input-style:focus {
    border: 1px solid #d0551c;
  }
  .alt-heading{
    font-weight: 600;
    padding-bottom:2rem;
    font-size: .875rem;
    color: #333;
    font-family: 'inter';
  }
  .alt-desc{
    color: #b2b3c4!important;
    margin-bottom: 0.25rem!important;
    font-size: .875rem!important;
    font-family: 'inter'!important;
  }
  .alt-changes{
    font-size: .875rem;
    margin-right: 0.5rem;
    color: #333;
    font-family: 'inter';

  }
  .alt-cancel{
    background-color: #00017806;
    border: 1px solid #00017806;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem; 
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.25rem;
    color: #333;
  }
  .alt-cancel:hover{
    background-color: #fe6d291a;
  }

  .alt-create{
    background-color: #fe6d29;
    border: 1px solid #fe6d29;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .875rem; 
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.25rem;
    color: #fff;
  }
  .alt-create:hover{
    background-color: #d0551c;
  }