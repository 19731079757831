.pro-email-input {
    border: none;
    outline: none;
    width: 100%;
    padding: 0.45rem 0.75rem;
    background-color: #f9fafc;
    border-radius: 0.25em;
  }
  .pro-email-input:focus {
    border: 1px solid #fe6d29;
  }
  .pro-email-input::placeholder {
    color: #a0aec0;
    font-family: 'inter';
    font-size: 1rem;
  }