
mark{
  background-color: yellow !important;
}

.form-select {
  margin-top: 4px;
  border: groove;
  border-radius: 3.5px;
}
.base-screen {
  background-color: whitesmoke;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.icon-main {
  color: slategrey;
  position: relative;
  right: 22px !important;
}
.slt-editor {
  min-height: 11in;
  padding-top: 4%;
  line-height: 12px;
  margin-top: 2rem;
  color: #333333;
  box-shadow: 0 0 5px rgba(59,65,94,0.2);
  background-color: white;
  font-family: courier-prime, CourierNext, monospace, monospace;
  font-size: 12px;
  font-weight: 400;
  padding-right: 10%;
  padding-left: 10%;
}
.dialogue {
  width: 60%;
  margin-left: 21.3%;
  padding: 0px !important;
  margin-top: 0px;
  margin: auto;
  display: block;
}
.transition {
  text-align: right;
  text-transform: uppercase;
}

@media print {
  .toolbar {
    display: none !important;
  }
  mark{
    background-color: yellow !important;
  }
  .slt-editor {
    min-height: 11in;
    background-color: white;
    box-shadow: none;
    font-size: 10px !important;
    padding-top: 0px;
    margin-top: 0%;
    font-weight: 400;
    padding-right: 2%;
    padding-left: 2%;
    font-family: courier-prime, CourierNext, monospace, monospace;
  }
  .offset-md-3 {
    width: 100%;
  }
  .icon-main {
    display: none;
  }
  .page-break {
    opacity: 0 !important;
  }
}

.dropbtn {
  background-color: transparent;
  font-size: 15px;
  bottom: -4px !important;
}

.dropdown {
  position: relative;
  height: 0px;
}

.scren-taginput {
  /* style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #fe6d29' }} */
  border: 1px solid #fe6d29;
              
}

.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  width: max-content;
  font-size: 11px;
  
  z-index: 1;
  cursor: pointer;
  top: 30px;
  left: -10px;
  color: #333;
  outline: 0;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  border-radius: 12px;
  padding: 8px;
}

.dropdown-content-item {
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  border-radius: 6px;
}

.dropdown-content .icon {
  font-size: 14px;
  position: relative;
  left: 14px;
  color: #708091;
}
.dropdown-content a {
  color: black;
  padding: 2px 3px;
  display: block;
  font-family: cursive;
  border-radius: 5px;
  text-align: center;
}
.page-break {
  width: 137%;
  display: block;
  margin-left: -18.5%;
}
.page-number {
  text-align: right;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.mr-35 {
  margin-right: 35px;
}

.header {
  position: absolute;
  top: 20px;
  font-size: 14px;
  left: 20px;
  font-weight: 500;
  color: #a1a1a1;
}

.bgWhiteSmoke {
  background-color: #f9fafc;
}

.header-input {
  border: none;
  width: 700px;
  font-size: 14px;
  font-weight: 500;
  font-family: courier-prime, CourierNext, monospace, monospace;
  color: #a1a1a1;
  outline: none;
}

.header-page {
  position: absolute;
  top: 20px;
  right: 20px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  pointer-events: none;
  font-size: 14px;
  font-weight: 500;
  color: #a1a1a1;
}

.footer-page {
  position: absolute;
  bottom: 20px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  pointer-events: none;
  right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #a1a1a1;
}

.sidebar-wrapper {
  background-color: #fff;
  height: 100%;
  min-width: 350px;
}

.sidebar-wrapper > .container {
  margin: 0;
  padding: 0;
}


.footer {
  height: 50px;
  background: white;
  /* top: 1px; */
  padding: 10px;
  padding-left: 10px;
  padding-bottom: 40px;
  justify-content: end;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #a1a1a1;
}

.getting-started-element-div {
  text-align: center !important;
  width: 100%;
  text-transform: uppercase;
  justify-content: center;
  margin-top: 10px;
  font-weight: 400;
  color: #333;
  line-height: 1;
  text-indent: 0em;
  cursor: text;
  outline: 0;
  white-space: pre-wrap;
  font-family: courier-prime, CourierNext, monospace, monospace;
  font-size: 17.3333px !important;

  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  position: relative;
}

.socialLoginsBtn {
  font-size: 15px !important;
  font-weight: 700;
  width: 131px;
  line-height: 1.7142857142857142;
  text-transform: capitalize;
}

.OrLoginWith {
  font-weight: 700;
  line-height: 1.7142857142857142;
  font-size: 0.9375rem;
  font-family: Public Sans,sans-serif;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* width */
.deskSidebarDrawer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.deskSidebarDrawer::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.deskSidebarDrawer::-webkit-scrollbar-thumb {
  background: #d8d8d8; 
}

/* Handle on hover */
.deskSidebarDrawer::-webkit-scrollbar-thumb:hover {
  background: #888888; 
}

blockquote {
  background: #dcdcdc29 !important;
  border-left: 2px solid #c1bebe !important;
  padding-left: 10px !important;
}

.duo-dialogue {
  display: flex;
}

.duo-dialogue-left {
  width: 50%;
  text-align: center;
}

.duo-dialogue-right {
  width: 50%;
  text-align: center;
}


/* table {
  border-collapse: collapse;
} */

td {
  /* padding: 10px; */
  /* border: 2px solid #ddd; */
  width: 3.14in !important;
}

tr {
  display: flex !important;
  /* width: 8.27in !important; */
  text-align: center !important;
  vertical-align: inherit;
  border-color: inherit;
  gap: 14px;
}

.suggestedMention {
  padding: 3px 3px 2px;
  margin: 0 1px;
  vertical-align: baseline;
  display: inline-block;
  border-radius: 4px;
  background-color: #eee;
  font-size: 0.9em;
}

.headerEditable {
  display: block !important;
}

.footerEditable {
  display: block !important;
}

.header-input {
  position: relative;
  left: 10px;
}

[data-slate-placeholder="true"] {
  top: 6px !important;
}

.printable-div {
  position: relative;
  min-height: 54em;
  max-height: auto;
  margin-top: 24px;
  color: #333;
  line-height: 17px;
  font-weight: 400;

  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  padding: 70px 120px !important;
  
}

.header-page {
  display: block !important;
}

.footer-page {
  display: block !important;
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */


.audio-react-recorder__canvas {
  width: 300px;
  height: 50px;
}

.comment-popup-rec .audio-react-recorder__canvas {
  width: 300px;
  height: 30px;
  margin-left: 20px;
  position: relative;
  bottom: 15px;
}

/* .voice-message.playing {
  border: 2px solid #fe6d29;
  border-radius: 50px;
  background-color: #f0f8ff; 
} */


.voice-message {
  position: relative;
  display: inline-block;
}

.voice-message.playing audio {
  border: 2px solid #fe6d29;
  border-radius: 50px;
  opacity: 0.1; /* Fade the audio player */
  pointer-events: none; /* Disable interaction while playing */
}

.voice-message.playing::before {
  content: "Playing...";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #fe6d29; /* Color of the text */
  background-color: #fff; /* Semi-transparent background */
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 2;
}

.audio-control-button {
  width: 40px;
  position: fixed;
  top: 130px;
  left: 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fe6d29; /* Or any color you prefer for the circle background */
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 99;
}

.audio-control-button:hover {
  background-color: #e55a1e; /* Optional: Darker shade on hover */
}

.audio-icon {
  color: #fff; /* White color for the icon */
  font-size: 20px; /* Adjust size as needed */
}


.editor {
  position: relative;
  min-height: 54em;
  transition: margin-left 0.3s ease;
  max-height: auto;
  background: rgb(255, 255, 255);
  width: 9.27in !important;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 5px rgba(59,65,94,0.2);
  color: #333;

  line-height: 1;
  text-indent: 0em;
  cursor: text;
  outline: 0;
  white-space: pre-wrap;
  font-family: courier-prime, CourierNext, monospace, monospace;
  font-size: 17.3333px !important;

}
@media (max-width:768px) {
  .editor {
    width: 100% !important;
  }
  .printable-div {
    padding: 70px 30px !important;
  }
}

.marginLeft > div {
  margin-left: 12% !important;
  transition: margin-left 0.3s ease;
}

.textCenter {
  text-align: center !important;
}


.audio-icon2 {
  color: #fff; /* White color for the icon */
  font-size: 10px; /* Adjust size as needed */
}

.custom-audio-player {
  display: flex;
  align-items: center;
  gap: 2px;
}

.play-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px; /* Adjust size for Font Awesome icon */
}

.play-button:hover {
  background-color: #0056b3;
}

.waveform-container {
  width: 60px; /* Adjust width as needed */
  overflow: hidden;
}

.waveform-gif {
  width: 100%; /* Fit the container */
  height: auto; /* Maintain aspect ratio */
}


@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in {
  animation: slideInRight 0.5s ease forwards; /* Animate sliding in */
}


.dash-scr-input {
  border: 1px solid #e0e0e0;
}