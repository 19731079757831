.welcome-input {
    /* padding: 1rem; */
    height: 44px;
    outline: none;
  padding-left: 5px;
    background-color: #f9fafc;
    border-radius: 0.25em;
    color: #000;
    border: 2px solid transparent;
    transition: all linear 0.2s;
    font-family: 'inter';
    font-size: 1rem;
    width: 100%;
  }
  .welcome-input:focus {
    border: 2px solid #fe6d29;
  }
  .welcome-input::placeholder {
    color: #b2b3c4;
  }
  .alr-con{
      color:#b2b3c4;
  }
