.refer-inp{
    border: none;
    outline: none;
    padding: 0.45rem 0.75rem;
    background-color: #f9fafc;
    border-radius: 0.25em;
    width: 80%;

}
.refer-inp:focus{
    border: 1px solid #fe6d29;
}