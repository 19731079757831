.help-input {
  border: none;
  outline: none;
  font-family: 'inter';
  padding: 0.45rem 0.75rem;
  background-color: #f9fafc;
  border-radius: 0.25em;
  color: #000;
  width: 100%;
}
.help-input:focus{
    border: 1px solid #fe6d29;
}
.help-input::placeholder{
    color:"#ccc"
}